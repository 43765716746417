import React, { useEffect, useContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Header, Footer } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import { Menu } from '../../components/espace-membre'

import User from '../../class/User'

export default function MotDePasse() {

    const navigate = useNavigate()

    const context = useContext(Context)

    const [flash, setFlash]= useState({})

    const modifierMotdepasse = async (e) => {

        e.preventDefault()

        const inputs = e.target

        if(inputs.password.value !== inputs.password_verif.value){

            setFlash({message: "Il semble que les deux mots de passe que vous avez entré ne sont pas identique.", type: 'error'})

            return

        }

        const results = await User.modifierMotdepasse(inputs)

        if(results.success){

            setFlash({message: results.message, type: 'succes'})

        }else{

            setFlash({message: results.message, type: 'error'})

        }

    }

    useEffect(() => {

        Func.session(context, navigate)

        document.title = "Mo mot de passe - " + APP_NAME

    }, [])


    return (

        <div>

            <Header />
                
            <div className="content dashboard">
                <div className="flex c ai-start">

                    <Menu data="mot-de-passe"/>

                    <div className="cont col-78">
                        
                        <h2 className='m_mt20'>Modifier mon mot de passe</h2>

                        { flash !== {} && <p className={ flash.type }>{ flash.message }</p>}

                        <form method='POST' className='gla-form pt20' onSubmit={(e) => modifierMotdepasse(e)}>

                            <div>
                                <label className="mb10">Mot de passe actuel <span className='cl4'>*</span></label>
                                <input type="password" name="old_password" placeholder="******" required={true} minLength={6}/>
                            </div>

                            <div className='grid2'>

                                <div>
                                    <label className="mb10">Nouveau mot de passe <span className='cl4'>*</span></label>
                                    <input type="password" name="password" placeholder="******" required={true} minLength={6}/>
                                </div>

                                <div>
                                    <label className="mb10">Retappez le mot de passe <span className='cl4'>*</span></label>
                                    <input type="password" name="password_verif" placeholder="******" required={true} minLength={6}/>
                                </div>

                            </div>

                            <input type="submit" value="Modifer" className='btn bg3 cl1 brc3 brc1solid' />

                        </form>

                    </div>
                </div>
            </div>

            <Footer/>

        </div>

    )

}