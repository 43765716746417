import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";

import { Context } from './class/Context'

import './style/style.css'
import './style/base.css'
import './style/mobile.css'

import User from './class/User'

import Accueil from './pages/Accueil'
import Connexion from './pages/Connexion'
import Inscription from './pages/Inscription'

import LouerMaVoiture from './pages/LouerMaVoiture'

import MonEspace from './pages/mon-espace/MonEspace'
import ReservationsEnvoyees from './pages/mon-espace/ReservationsEnvoyees'
import AnnoncesPage from './pages/mon-espace/AnnoncesPage'
import Publier from './pages/mon-espace/Publier'
import ModifierAnnonce from './pages/mon-espace/ModifierAnnonce'

import AnnonceSingle from './pages/AnnonceSingle'

import Discussions from './pages/mon-espace/Discussions'
import Discussion from './pages/mon-espace/Discussion'

import Reserver from './pages/Reserver'

import Informations from './pages/mon-espace/Informations'
import MotDePasse from './pages/mon-espace/MotDePasse'
import Avatar from './pages/mon-espace/Avatar'

import EnvoyerMessage from './pages/EnvoyerMessage'

import Reservation from './pages/mon-espace/Reservation'

import Page_404 from './pages/Page_404'

import Categorie from './pages/Categorie'
import Search from './pages/Search'
import Contrat from './pages/mon-espace/Contrat'
import ContratFinal from './pages/mon-espace/ContratFinal'
import Ville from './pages/Ville'
import Page from './pages/Page';
import ValiderEmail from './pages/ValiderEmail';
import MotDePasseRecup from './pages/MotDePasse'

function App (){

  const [session, setSession] = useState(false)
  const [messages, setMessages] = useState(0)

  useEffect(() => {

    User.connected(setSession, setMessages)
    
    setInterval(() => User.connected(setSession, setMessages), 30000)

  }, [])

  return (

    <Context.Provider value={{session: session, setSession: setSession, messages: messages}}>

      <Router>
        
        <Routes>

          <Route exact path="/" element={<Accueil />}></Route>

          <Route path="/connexion" element={<Connexion />}></Route>
          <Route path="/inscription" element={<Inscription />}></Route>

          <Route path="/mot-de-passe-oublie" element={<MotDePasseRecup />}></Route>
          <Route path="/valider-email" element={<ValiderEmail />}></Route>

          <Route path="/categorie/:url" element={<Categorie />}></Route>
          <Route path="/ville/:id" element={<Ville />}></Route>

          <Route path="/search" element={<Search />}></Route>


          {/* Espace membre */}

          {

            session

            &&

            <>

              <Route path="/mon-espace" element={<MonEspace />}></Route>
              <Route path="/mon-espace/envoyees" element={<ReservationsEnvoyees />}></Route>

              <Route path="/mon-espace/annonces" element={<AnnoncesPage />}></Route>
              <Route path="/mon-espace/publier" element={<Publier />}></Route>
              <Route path="/mon-espace/modifier-annonce/:id" element={<ModifierAnnonce />}></Route>

              <Route path="/mon-espace/modifier-annonce/:id" element={<ModifierAnnonce />}></Route>

              <Route path="/mon-espace/mes-informations" element={<Informations />}></Route>
              <Route path="/mon-espace/mot-de-passe" element={<MotDePasse />}></Route>
              <Route path="/mon-espace/photo-de-profil" element={<Avatar />}></Route>

              <Route path="/mon-espace/discussions" element={<Discussions />}></Route>
              <Route path="/mon-espace/discussion/:id" element={<Discussion />}></Route>

              <Route path="/mon-espace/reservation/:id" element={<Reservation />}></Route>
              <Route path="/mon-espace/contrat/:id" element={<Contrat />}></Route>
              <Route path="/mon-espace/finaliser-contrat/:contrat_id" element={<ContratFinal />}></Route>

            </>

          }

          {/* Pages ------------- */}

          <Route path="/louer-ma-voiture" element={<LouerMaVoiture />}></Route>

          <Route path="/annonce/:id" element={<AnnonceSingle />}></Route>

          <Route path="/reserver/:id" element={<Reserver />}></Route>

          <Route path="/envoyer-message/:id" element={<EnvoyerMessage />}></Route>

          <Route path="/page/:url" element={<Page />}></Route>

          <Route path="/404" element={<Page_404 />}></Route>

          <Route path='*' element={<Page_404 />}/>
          
        </Routes>

      </Router>

    </Context.Provider>
    
  )

}

export default App;