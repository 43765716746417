import React, { useState, useEffect, useContext } from 'react';

import { Link, useSearchParams } from 'react-router-dom';

import { Header, Footer, Loading } from '../components/imports'

import { APP_NAME } from '../constants/general'
import Func from '../class/Func';

function ValiderEmail() {
    
    const [loading, setLoading] = useState(true)

    const [ get_params ] = useSearchParams()

    const [flash, setFlash] = useState('')

    const validate = async (email, token) => {

        if(email !== null && token !== null){

            const results = await Func.fetch("users/validerEmail", {email, token}, false)
    
            if(results.success){
    
                setFlash({message: results.message, type: "succes"})
    
            }else{

                setFlash({message: results.message, type: "error"})
                            
            }

            setLoading(false)

        }else{

            setLoading(false)

            setFlash({message: "Impossible de valider votre adresse email avec ce lien.", type: "error"})

        }
    
        
    
    }

    useEffect(() => {

        document.title = "Validation de l'adresse email - " + APP_NAME

        let email = get_params.get("email")
        let token = get_params.get("token")

        validate(email, token)


    }, [])

    return (

        <div>

            <Header />

            <div className="page register pos-r content">

                <div className='pos-r m_mt_40'>

                    <div className="col-4 m0a pt80 pb80 ta-center m_p20">

                        <h1 className='mb30 fw4'>Adresse mail validée avec succès</h1>

                        { flash !== '' && <p className={ flash.type + " fz08 mb0"}>{ flash.message }</p>}

                        <div className='mt40'>
                        
                            <Link to={'/'} className='mt40 btn bg4 cl1'>Retour</Link>

                        </div>

                        {

                            loading

                            &&

                            <Loading />

                        }

                    </div>

                </div>

            </div>

            <Footer />

        </div>
        
    )

}

export default ValiderEmail