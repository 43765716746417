import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link } from 'react-router-dom';

import { APP_NAME } from '../constants/general'

import { Context } from '../class/Context'

import User from '../class/User'

import Func from '../class/Func'

import { Text } from '../constants/Text'

const Register = async (e,setError, navigate, setSession) => {

    e.preventDefault()

    let inputs = e.target

    let name = inputs.name

    let email = inputs.email

    let pass = inputs.pass

    if(name.value !== "" && email.value !== "" && pass.value !== "" && inputs.checkbox_conditions.checked ){

     const results = await User.register(inputs);
    
     if(results.success){
       
        Func.saveSession(results.data, setSession)

        Func.setFlash(Text.message_inscription)

        navigate('/mon-espace')

     }else{

        setError(results.message)
     }

       
    }else{
      
        setError( 'Assurez-vous que tout les champs sont remplis')
    }
   
}


function Inscription() {
    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('')

    const navigate = useNavigate()

    const context = useContext(Context)


    useEffect(() => {

        document.title = "Inscription - " + APP_NAME

    }, [])


    return (

        <div className="login flex row-10">

            <div className="col-4 side bg5 p50 pos-r row-10 flex flex-col">
                <div>
                    
                    <Link to={"/"} className="mb50 d-block"><img src="image/large/logo.png" alt={APP_NAME} className="logo mr20" /></Link>
                    
                    <p className="mb20 fz12">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequuntur cupiditate nam!</p>

                    <p className="mb10"><span className="icon cl4 mr10 ri-shield-check-fill"></span>Tempore necessitatibus facere sapiente!</p>
                    <p className="mb10"><span className="icon cl4 mr10 ri-shield-check-fill"></span>Adipisicing elit. Consequuntur cupiditate</p>

                </div>

                <img src="image/large/register-pic.png" alt="" className="img col-8 m0a" />

            </div>

            <div className="col-6 main row-10 p30">

                <div className="ta-end mb50">
                    <p>Vous n'êtes déjà inscris ? <Link to={"/connexion"} className="cl3">Se connecter</Link></p>
                </div>

                <div className="col-6 m0a">

                    <h2 className="mb30 fz2 cl2">S'inscrire</h2>
                    <p className="fz09 mb30 cl6">Créer un compte sur {APP_NAME}</p>

                    { error && <p className='error'>{error}</p>}

                    <form action="" method="POST" className="gla-form" onSubmit={(e) => Register(e, setError, navigate, context.setSession)}>

                        <label className="mb10">Nom complet</label>
                        <input type="text" name="name" placeholder="Nom" required={true} />

                        <label className="mb10">Adresse email</label>
                        <input type="text" name="email" placeholder="Email" required={true} />

                        <label className="mb30">Mot de passe</label>
                        <input type="password" name="pass" placeholder="******" required={true} />

                        <div className='cgu_accept'>
                            <input type="checkbox" name='checkbox_conditions' className='col-1' required={true}/> <p>j'accepte les <Link to={'/page/conditions-genrales-d-utilisation'}>Conditions générales d'utilisation</Link> de {APP_NAME}</p>
                        </div>

                        <input type="submit" name="log" value="S'inscrire" className="btn bg4 cl1 brc1solid brc4" />

                    </form>

                </div>

            </div>

        </div>

    )

}

export default Inscription