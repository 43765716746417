import React, { useState, useEffect } from 'react'

import { useNavigate, Link } from 'react-router-dom';

import { AVATAR_ROOT, PHOTOS_ROOT } from '../constants/general'

import Discussions from '../class/Discussions'
import Moment from 'react-moment'
import 'moment/locale/fr'
import Func from '../class/Func'

const me = localStorage.getItem('idU')

const Menu = (props) => {

    const navigate = useNavigate()

    const setModePro = async(e) => {

        localStorage.setItem('proMode', e.target.checked)

        navigate(0)
        
    }

    const renderCheckbox = () => {
        let value = false
        if(localStorage.getItem('proMode') == "true"){
            value = true
        }else{
            value = false
        }

        return(
            <label className='switch'>
                <input type='checkbox' defaultChecked={value} onChange={setModePro}/>
                <span className='slider'></span>
            </label>
        )
    }

    return ( 
        
        <div className='side col-22 bg5'>
            <h1 className="fz13 fw5 mb30 cl2">Espace membre</h1>
            
            <div className='flex ai-center mb20 m_flex'>
                <span className='fz09 fw5'>Mode professionnel</span>
                {renderCheckbox()}
            </div>
            
            <ul>
                <h4 className='mb20'>Réservations</h4>
                
                <li className='ml20 fz09'><Link to={"/mon-espace"} className={ props.data == 'mon-espace' ? 'cl4' : 'cl2' }><span className="icon ri-calendar-line"></span>Gérer les réservations</Link></li>
                
                {localStorage.getItem('proMode') == "true" &&
                    <li><Link to={"/mon-espace/annonces"} className={ props.data == 'annonces' ? 'cl4' : 'cl2' }><span className="icon ri-car-line"></span>Mes annonces</Link></li>
                }
                {/* <li><Link to={"/mon-espace/favoris"} className={ props.data == 'favoris' ? 'cl4' : 'cl2' }><span className="icon ri-heart-fill"></span>Mes Favoris</Link></li> */}
                
                <h4 className='mb20 mt20'>Paramètres</h4>
                <li><Link to={"/mon-espace/mes-informations"} className={ props.data == 'mes-informations' ? 'cl4' : 'cl2' }><span className="icon ri-equalizer"></span>Mes Informations</Link></li>
                <li><Link to={"/mon-espace/mot-de-passe"} className={ props.data == 'mot-de-passe' ? 'cl4' : 'cl2' }><span className="icon ri-lock"></span>Mot de passe</Link></li>
                <li><Link to={"/mon-espace/photo-de-profil"} className={ props.data == 'photo-de-profil' ? 'cl4' : 'cl2' }><span className="icon ri-user"></span>photo de profile</Link></li>
            </ul>
        </div>

    );

}

const AnnonceCard = (props) => {

    return ( 

        <div className="article cl2">
                                
            <img src={ PHOTOS_ROOT + "small/" + props.data.name  + ".jpg" }/>
            
            <div className="cnt">
                <span className='t mb10'>{ props.data.titre }</span>

                <div className='grid2 ta-center'>  
                    <Link to={"/annonce/" + props.data.idA} className="cl1 bg2 p5 br8">Consulter</Link>
                    <Link to={"/mon-espace/modifier-annonce/" + props.data.idA} className="cl1 bg2 p5 br8">Modifier</Link>
                </div>
                
            </div>
        </div>

    )

}

const DiscussionsCard = () => {

    const [discussions, setDiscussions] = useState(false)

    const mesDiscussions = async () => {

        let results = await Discussions.mes_discussions({limit: 12})
    
        if(results.success){
            
            setDiscussions(results.data)

        }else{

            setDiscussions(results.message)

        }
    
    }

    useEffect(() => {

        mesDiscussions()

    }, [])

    return (
        <div className='messages_side side col-3 bg5'>

            <h2 className='fz13 fw5 mb30 cl2'>Mes discussions</h2>

            {

                discussions

                ?

                    typeof discussions == 'object'

                    ?

                    discussions.map(element => <DiscussionCard data={element} key={ element.id }/>)

                    :

                    <p className='fz08'>{discussions}</p>

                :

                    <div className='flex jc-center mt50 mb50'>
                        <div className="loader"></div>
                    </div>

            }

        </div>
    )

}

const DiscussionCard = (props) => {

    const discussionClick = (id) => {

        let elem = document.querySelector('#discussion_' + id)

        elem.classList.remove('fw7')

        if(elem.querySelector('.badge')) elem.querySelector('.badge').remove()

    }

    let badge = ''

    if(me == props.data.u1){

        if(props.data.new_u1 > 0) badge = <span className='badge'>{ props.data.new_u1 }</span>

    }else{

        if(props.data.new_u2 > 0) badge = <span className='badge'>{ props.data.new_u2 }</span>

    }

    return(

        <Link to={"/mon-espace/discussion/" + props.data.id } className={ `user_card cl2 ${badge == '' ? '' : ' fw7'}` } id={'discussion_' + props.data.id} onClick={() => discussionClick(props.data.id) }>
                                    
            <img src={ Func.avatar(props.data.avatar) } className='avatar'/>

            <div>
                <h3>{ props.data.name } { badge }</h3>
                <p><Moment interval='1000' locale="fr" fromNow>{ props.data.date }</Moment></p>
            </div>

        </Link>

    )

}

const Message = (props) => {

    return(

        <div className={ `message ${props.data.sender == me ? ' me' : ''}`}>
            <p>{ props.data.msg }</p>
            <time><Moment interval='1000' locale="fr" fromNow>{ props.data.date }</Moment></time>
        </div>

    )

}

export { Menu, AnnonceCard, DiscussionsCard, DiscussionCard, Message };