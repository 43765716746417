import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, Navigate } from 'react-router-dom';

import { Header, Footer } from '../../components/imports'

import { WEBROOT, APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import User from '../../class/User'

import Func from '../../class/Func'

import { Menu } from '../../components/espace-membre';

function Informations() {

    const navigate = useNavigate()

    const [flash, setFlash]= useState({})

    const [infos, setInfos]= useState(false)

    const context = useContext(Context)

    const getInfos = async () => {

        const results = await User.informations()

        if(results.success){

            setInfos(results.data)

        }else{

            navigate('/404')
        }

    }

    const modifierInformations = async (e) => {

        e.preventDefault()

        const inputs = e.target

        const results = await User.modifierInformations(inputs)

        if(results.success){

            setFlash({message: results.message, type: 'succes'})

        }else{

            setFlash({message: results.message, type: 'error'})

        }

    }

    useEffect(() => {

        Func.session(context, navigate)

        getInfos()

        document.title = "Mes informations - " + APP_NAME

    }, [])


    return (

        <div>

            <Header />
                
            <div className="content dashboard">
                <div className="flex c ai-start">

                    <Menu data="mes-informations"/>

                    <div className="cont col-78">
                        
                        <h2 className='m_mt20'>Mes informations</h2>

                        { flash != {} && <p className={ flash.type }>{ flash.message }</p>}

                        {

                            infos
                        
                            ?

                            <form method='POST' className='gla-form pt20' onSubmit={(e) => modifierInformations(e)}>

                                <div>
                                    <label className="mb10">Nom complet <span className='cl4'>*</span></label>
                                    <input type="text" name="name" placeholder="Votre nom complet ..." required={true} defaultValue={ infos.name }/>
                                </div>

                                <div className='grid2'>

                                    <div>
                                        <label className="mb10">Adresse email <span className='cl4'>*</span> { infos.email_token == 0 ? <span style={{color: 'gren'}}>(Vérifiée)</span> : <><span style={{color: 'red'}}>(Non vérifiée)</span> . <span style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>Renvoyer le mail</span></> }</label>
                                        <input type="text" name="email" placeholder="Adresse email ..." required={true} defaultValue={ infos.email } disabled={infos.email_token == 0 ? true : false}/>
                                    </div>

                                    <div>
                                        <label className="mb10">Numéro de téléphone <span className='cl4'>*</span></label>
                                        <input type="text" name="tele" placeholder="Numéro de téléphone ..." defaultValue={ infos.tele }/>
                                    </div>

                                </div>

                                <div>
                                    <label className="mb10">Adresse <span className='cl4'>*</span></label>
                                    <input type="text" name="adresse" placeholder="Rue, Ville, Wilaya ..." defaultValue={ infos.adresse }/>
                                </div>

                                <input type="submit" value="Modifer" className='btn bg3 cl1 brc3 brc1solid' />

                            </form>

                            :

                            <div className='flex jc-center'><div className='loader mt100'></div></div>

                        }

                    </div>
                </div>
            </div>

            <Footer/>

        </div>

    )

}

export default Informations