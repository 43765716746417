import React, { useEffect, useState, useRef } from 'react';

import { useParams, useNavigate, useSearchParams, Link } from 'react-router-dom';

import { Header, Footer } from '../../components/imports'

import { APP_NAME, PHOTOS_ROOT, WEBROOT } from '../../constants/general'
import SignatureCanvas from 'react-signature-canvas'
import Func from '../../class/Func';
import Annonces from '../../class/Annonces';
import Reservations from '../../class/Reservations';

function ContratFinal() {

    const { contrat_id } = useParams()
    
    const [ get_params ] = useSearchParams()

    const navigate = useNavigate()

    const [error, setError] = useState(false)
   
    //Permis Photos
    const [permisRecto, setPermisRecto] = useState(false)
    const [permisVerso, setPermisVerso] = useState(false)
    //Voitures Photos
    const [faceAvant, setFaceAvant] = useState(false)
    const [angleAvantDroit, setAngleAvantDroit] = useState(false)
    const [aileDroite, setAileDroite] = useState(false)
    const [angleArriereDroit, setAngleArriereDroit] = useState(false)
    const [faceArriere, setFaceArriere] = useState(false)
    const [angleArriereGauche, setAngleArriereGauche] = useState(false)
    const [aileGauche, setAileGauche] = useState(false)
    const [angleAvantGauche, setAngleAvantGauche] = useState(false)
    //Signature
    const [openModelSig, setOpenModalSig] = useState(false);
    const [selectedModal, setSelectedModal] = useState(0);
    const signatureLocataireRef = useRef()
    const signatureProprioRef = useRef()
    const [signatureLocataire, setSignatureLocataire] = useState(false);
    const [signatureProprio, setSignatureProprio] = useState(false);

    const getContrat = async (id) => {

        const results = await Func.fetch("reservations/contratInfos", {id, contrat_id})

        console.log(results)

        if(results.success){

            if(results.contrat.photo_pc_recto !== null) setPermisRecto(results.contrat.photo_pc_recto)
            if(results.contrat.photo_pc_verso !== null) setPermisVerso(results.contrat.photo_pc_verso)
            if(results.contrat.photo_avant !== null) setFaceAvant(results.contrat.photo_avant)
            if(results.contrat.photo_arriere !== null) setFaceArriere(results.contrat.photo_arriere)
            if(results.contrat.photo_droite !== null) setAileDroite(results.contrat.photo_droite)
            if(results.contrat.photo_gauche !== null) setAileGauche(results.contrat.photo_gauche)
            if(results.contrat.photo_avant_droit !== null) setAngleAvantDroit(results.contrat.photo_avant_droit)
            if(results.contrat.photo_avant_gauche !== null) setAngleAvantGauche(results.contrat.photo_avant_gauche)
            if(results.contrat.photo_arriere_droit !== null) setAngleArriereDroit(results.contrat.photo_arriere_droit)
            if(results.contrat.photo_arriere_gauche !== null) setAngleArriereGauche(results.contrat.photo_arriere_gauche)
            
            if(results.contrat.photo_sig_client !== null) setSignatureLocataire(results.contrat.photo_sig_client)
            if(results.contrat.photo_sig_proprietaire !== null) setSignatureProprio(results.contrat.photo_sig_proprietaire)
            
        }else{

            navigate('/404')

        }

    }

    const contratValidation = async () => {

        const results = await Func.fetch("reservations/contratValidation", {contrat_id})

        console.log(results)

        if(results.success){

            window.open('https://ekriny.com/contrat/?token='+ results.token +'&id=' + results.contrat_id)

            navigate('/mon-espace')

        }else{

            setError(results.message)

        }

    }

    const uploadPhotos = async (e, file_name) => {

        let file = e.target.files[0]
    
        if(file.size == 0 || file.name == null) setError("Impossible d'ajouter cette photo une erreur est servenu.")
    
        // setLoading(true)
    
        let results = await Reservations.uploadPhoto(file, contrat_id, file_name)
    
        // setLoading(false)

        console.log(results)
    
        if (results.success){

            if(results.file_name == 'photo_pc_recto') setPermisRecto(results.image_name)
            if(results.file_name == 'photo_pc_verso') setPermisVerso(results.image_name)
            if(results.file_name == 'photo_avant') setFaceAvant(results.image_name)
            if(results.file_name == 'photo_avant_droit') setAngleAvantDroit(results.image_name)
            if(results.file_name == 'photo_arriere') setFaceArriere(results.image_name)
            if(results.file_name == 'photo_droite') setAileDroite(results.image_name)
            if(results.file_name == 'photo_gauche') setAileGauche(results.image_name)
            if(results.file_name == 'photo_avant_gauche') setAngleAvantGauche(results.image_name)
            if(results.file_name == 'photo_arriere_droit') setAngleArriereDroit(results.image_name)
            if(results.file_name == 'photo_arriere_gauche') setAngleArriereGauche(results.image_name)
    
        }else{
    
            setError(results.message)
            
        }
    
    }

    const uploadSignature = async (image, file_name) => {
    
        // setLoading(true)
    
        const results = await Func.fetch("reservations/uploadSignature", {image, contrat_id, file_name})
    
        // setLoading(false)
    
        if (results.success){

            if(results.file_name == 'photo_sig_client') setSignatureLocataire(results.image_name)
            if(results.file_name == 'photo_sig_proprietaire') setSignatureProprio(results.image_name)
    
        }else{
    
            setError(results.message)
            
        }
    
    }

    const signatureLocataireUpload = () => {

        if (signatureLocataireRef.current) {

            // if(signatureLocataire.current.isEmpty()){
            //     alert('Veuillez ajouter une signature');
            //     return
            // }

            const dataURL = signatureLocataireRef.current.toDataURL()

            uploadSignature(dataURL, 'photo_sig_client')
            
        }

        setOpenModalSig(false)

    }

    const signatureProprioUpload = () => {

        if (signatureProprioRef.current) {

            const dataURL = signatureProprioRef.current.toDataURL()

            console.log(dataURL)

            uploadSignature(dataURL, 'photo_sig_proprietaire')

        }

        setOpenModalSig(false)
    }


    useEffect(() => {

        document.title = "Contrat - " + APP_NAME

        window.scrollTo(0, 0)

        getContrat(contrat_id)
        
    }, [])


    return (
        <div>
            <Header />
            
            <div className='content dashboard'>

                <div className='col-5 m0a bg5 p20 br20'>
                    <h2 className='mb30'>Finaliser le contrat de location</h2>

                    {(error !== false) && <p className="error">{error}</p>}

                    {(get_params.get('created_at') !== undefined) && <p className="succes">Ajoutez les photos du véhicule, votre permis de conduire et vos signatures pour finaliser le contrat.</p>}

                    {/* Photos du permis -------------------------------------------------------- */}

                    <div className='brc1Top brc6 pt20 pb20'>
                        <span className='d-block mb20 cl6 fw6'>Photos du permis de conduire du locataire</span>

                        <div className='grid2'>
                            <div>
                                <label>Recto du permis de conduire <span className='cl4'>*</span></label>
                                
                                { permisRecto && <div style={{width: '150px', margin: '30px 10px 10px'}}><img src={WEBROOT + 'image/contrat/' + contrat_id + '/' + permisRecto + '.jpg'} style={{width: '150px',height: '150px', marginBottom: '10px', borderRadius: '10px', objectFit: 'cover'}} /></div> }
                                
                                <span className="btn2 brc6 brc1solid cl6 bgt ta-center mb10 d-block mt10 hover-bg1" onClick={ (e) => document.querySelector('#permisRecto').click()}>{ permisRecto ? 'Modifier la photo' : 'Ajouter une photo' }</span>
                                <input type="file" name='permisRecto' id='permisRecto' accept='image/*' onChange={ e => uploadPhotos(e, 'photo_pc_recto') } className="d-none" style={{display: 'none'}}/>
                            </div>
                            <div>
                                <label>Verso du permis de conduire<span className='cl4'>*</span></label>
                                
                                { permisVerso && <div style={{width: '150px', margin: '30px 10px 10px'}}><img src={WEBROOT + 'image/contrat/' + contrat_id + '/' + permisVerso + '.jpg'} style={{width: '150px',height: '150px', marginBottom: '10px', borderRadius: '10px', objectFit: 'cover'}} /></div> }
                                
                                <span className="btn2 brc6 brc1solid cl6 bgt ta-center mb10 d-block mt10 hover-bg1" onClick={ (e) => document.querySelector('#permisVerso').click()}>{ permisVerso ? 'Modifier la photo' : 'Ajouter une photo' }</span>
                                <input type="file" name='permisVerso' id='permisVerso' accept='image/*' onChange={ e => uploadPhotos(e, 'photo_pc_verso')  } className="d-none" style={{display: 'none'}}/>
                            </div>
                        </div>
                            
                    </div>

                    {/* Photos extérieur du véhicule -------------------------------------------------------- */}

                    <div className='brc1Top brc6 pt20 pb20'>
                        <span className='d-block mb20 cl6 fw6'>Photos extérieur du véhicule</span>

                        <div className='grid2 mb15'>
                            <div>
                                <label>Face avant <span className='cl4'>*</span></label>
                                
                                { faceAvant && <div style={{width: '150px', margin: '30px 10px 10px'}}><img src={WEBROOT + 'image/contrat/' + contrat_id + '/' + faceAvant + '.jpg'} style={{width: '150px',height: '150px', marginBottom: '10px', borderRadius: '10px', objectFit: 'cover'}} /></div> }
                                
                                <span className="btn2 brc6 brc1solid cl6 bgt ta-center mb10 d-block mt10 hover-bg1" onClick={ (e) => document.querySelector('#faceAvant').click()}>{ faceAvant ? 'Modifier la photo' : 'Ajouter une photo' }</span>
                                <input type="file" name='faceAvant' id='faceAvant' accept='image/*' onChange={ e => uploadPhotos(e, 'photo_avant') } className="d-none" style={{display: 'none'}}/>
                            </div>
                            <div>
                                <label>Face arrière <span className='cl4'>*</span></label>

                                { faceArriere && <div style={{width: '150px', margin: '30px 10px 10px'}}><img src={WEBROOT + 'image/contrat/' + contrat_id + '/' + faceArriere + '.jpg'} style={{width: '150px',height: '150px', marginBottom: '10px', borderRadius: '10px', objectFit: 'cover'}} /></div> }
                                
                                <span className="btn2 brc6 brc1solid cl6 bgt ta-center mb10 d-block mt10 hover-bg1" onClick={ (e) => document.querySelector('#faceArriere').click()}>{ faceArriere ? 'Modifier la photo' : 'Ajouter une photo' }</span>
                                <input type="file" name='faceArriere' id='faceArriere' accept='image/*' onChange={ e => uploadPhotos(e, 'photo_arriere') } className="d-none" style={{display: 'none'}}/>
                            </div>
                        </div>

                        <div className='grid2 mb15'>
                            <div>
                                <label>Aile Droite <span className='cl4'>*</span></label>

                                { aileDroite && <div style={{width: '150px', margin: '30px 10px 10px'}}><img src={WEBROOT + 'image/contrat/' + contrat_id + '/' + aileDroite + '.jpg'} style={{width: '150px',height: '150px', marginBottom: '10px', borderRadius: '10px', objectFit: 'cover'}} /></div> }
                                
                                <span className="btn2 brc6 brc1solid cl6 bgt ta-center mb10 d-block mt10 hover-bg1" onClick={ (e) => document.querySelector('#aileDroite').click()}>{ aileDroite ? 'Modifier la photo' : 'Ajouter une photo' }</span>
                                <input type="file" name='aileDroite' id='aileDroite' accept='image/*' onChange={ e => uploadPhotos(e, 'photo_droite') } className="d-none" style={{display: 'none'}}/>
                            </div>
                            
                            <div>
                                <label>Aile gauche <span className='cl4'>*</span></label>

                                { aileGauche && <div style={{width: '150px', margin: '30px 10px 10px'}}><img src={WEBROOT + 'image/contrat/' + contrat_id + '/' + aileGauche + '.jpg'} style={{width: '150px',height: '150px', marginBottom: '10px', borderRadius: '10px', objectFit: 'cover'}} /></div> }
                                
                                <span className="btn2 brc6 brc1solid cl6 bgt ta-center mb10 d-block mt10 hover-bg1" onClick={ (e) => document.querySelector('#aileGauche').click()}>{ aileGauche ? 'Modifier la photo' : 'Ajouter une photo' }</span>
                                <input type="file" name='aileGauche' id='aileGauche' accept='image/*' onChange={ e => uploadPhotos(e, 'photo_gauche') } className="d-none" style={{display: 'none'}}/>
                            </div>
                            
                        </div>

                        <div className='grid2 mb15'>

                            <div>
                                <label>Angle avant droit<span className='cl4'>*</span></label>

                                { angleAvantDroit && <div style={{width: '150px', margin: '30px 10px 10px'}}><img src={WEBROOT + 'image/contrat/' + contrat_id + '/' + angleAvantDroit + '.jpg'} style={{width: '150px',height: '150px', marginBottom: '10px', borderRadius: '10px', objectFit: 'cover'}} /></div> }
                                
                                <span className="btn2 brc6 brc1solid cl6 bgt ta-center mb10 d-block mt10 hover-bg1" onClick={ (e) => document.querySelector('#angleAvantDroit').click()}>{ angleAvantDroit ? 'Modifier la photo' : 'Ajouter une photo' }</span>
                                <input type="file" name='angleAvantDroit' id='angleAvantDroit' accept='image/*' onChange={ e => uploadPhotos(e, 'photo_avant_droit') } className="d-none" style={{display: 'none'}}/>
                            </div>
                            
                            <div>
                                <label>Angle avant gauche<span className='cl4'>*</span></label>

                                { angleAvantGauche && <div style={{width: '150px', margin: '30px 10px 10px'}}><img src={WEBROOT + 'image/contrat/' + contrat_id + '/' + angleAvantGauche + '.jpg'} style={{width: '150px',height: '150px', marginBottom: '10px', borderRadius: '10px', objectFit: 'cover'}} /></div> }

                                <span className="btn2 brc6 brc1solid cl6 bgt ta-center mb10 d-block mt10 hover-bg1" onClick={ (e) => document.querySelector('#angleAvantGauche').click()}>{ angleAvantGauche ? 'Modifier la photo' : 'Ajouter une photo' }</span>
                                <input type="file" name='angleAvantGauche' id='angleAvantGauche' accept='image/*' onChange={ e => uploadPhotos(e, 'photo_avant_gauche') } className="d-none" style={{display: 'none'}}/>
                            </div>
                        </div>

                        <div className='grid2 mb15'>

                            <div>
                                <label>Angle arrière droit<span className='cl4'>*</span></label>

                                { angleArriereDroit && <div style={{width: '150px', margin: '30px 10px 10px'}}><img src={WEBROOT + 'image/contrat/' + contrat_id + '/' + angleArriereDroit + '.jpg'} style={{width: '150px',height: '150px', marginBottom: '10px', borderRadius: '10px', objectFit: 'cover'}} /></div> }

                                <span className="btn2 brc6 brc1solid cl6 bgt ta-center mb10 d-block mt10 hover-bg1" onClick={ (e) => document.querySelector('#angleArriereDroit').click()}>{ angleArriereDroit ? 'Modifier la photo' : 'Ajouter une photo' }</span>
                                <input type="file" name='angleArriereDroit' id='angleArriereDroit' accept='image/*' onChange={ e => uploadPhotos(e, 'photo_arriere_droit') } className="d-none" style={{display: 'none'}}/>
                            </div>

                            <div>
                                <label>Angle arrière gauche<span className='cl4'>*</span></label>
                                
                                { angleArriereGauche && <div style={{width: '150px', margin: '30px 10px 10px'}}><img src={WEBROOT + 'image/contrat/' + contrat_id + '/' + angleArriereGauche + '.jpg'} style={{width: '150px',height: '150px', marginBottom: '10px', borderRadius: '10px', objectFit: 'cover'}} /></div> }

                                <span className="btn2 brc6 brc1solid cl6 bgt ta-center mb10 d-block mt10 hover-bg1" onClick={ (e) => document.querySelector('#angleArriereGauche').click()}>{ angleArriereGauche ? 'Modifier la photo' : 'Ajouter une photo' }</span>
                                <input type="file" name='angleArriereGauche' id='angleArriereGauche' accept='image/*' onChange={ e => uploadPhotos(e, 'photo_arriere_gauche') } className="d-none" style={{display: 'none'}}/>
                            </div>
                        </div>
                            
                    </div>

                    {/* Signatures du Locataire et du Propriétaire -------------------------------------------------------- */}
                    
                    <div className='brc1Top brc6 pt20 pb20'>
                        <span className='d-block mb20 cl6 fw6'>Signatures du Locataire et du Propriétaire</span>
                        <div className='grid2'>
                            <div>
                                <span>Signature du Locataire <span className='cl4'>*</span></span>

                                { signatureLocataire && <div style={{width: '150px', margin: '30px 10px 10px'}}><img src={WEBROOT + 'image/contrat/' + contrat_id + '/' + signatureLocataire + '.jpg'} style={{width: '150px',height: '150px', marginBottom: '10px', borderRadius: '10px', objectFit: 'cover'}} /></div> }

                                <button className='btn2 brc6 brc1solid cl6 bgt ta-center mb10 d-block hover-bg1 mt10' onClick={() => {setOpenModalSig(true); setSelectedModal(0)}}>{ signatureLocataire ? 'Modifier la signature' : 'Ajouter une signature' }</button>

                            </div>
                            <div>
                                <span>Signature du Propriétaire <span className='cl4'>*</span></span>

                                { signatureProprio && <div style={{width: '150px', margin: '30px 10px 10px'}}><img src={WEBROOT + 'image/contrat/' + contrat_id + '/' + signatureProprio + '.jpg'} style={{width: '150px',height: '150px', marginBottom: '10px', borderRadius: '10px', objectFit: 'cover'}} /></div> }

                                <button className='btn2 brc6 brc1solid cl6 bgt ta-center mb10 d-block hover-bg1 mt10' onClick={() => {setOpenModalSig(true); setSelectedModal(1)}}>{ signatureProprio ? 'Modifier la signature' : 'Ajouter une signature' }</button>
                            </div>
                        </div>
                        
                        {
                            openModelSig &&
                            <div className='modalContainer'>
                                <div className='modal'>

                                    <div className='flex jc-between ai-center mb20'>
                                        <span className='fw5'>{selectedModal == 0 ? "Signature du locataire" : "Signature du propriétaire"}</span>
                                        <button className='action bg6 cl1 brc0 icon ri-close-line fz11' onClick={() => setOpenModalSig(false)}></button>
                                    </div>
                                    
                                    <div className='signatureView'>
                                        <SignatureCanvas penColor='#000'
                                            canvasProps={{className: 'signature'}}
                                            ref={selectedModal == 0 ? signatureLocataireRef : signatureProprioRef}
                                        />
                                    </div>

                                    <div className='flex jc-end mt20'>
                                        <button className='btn2 bg3 cl1 mr10 brc0' onClick={() => selectedModal == 0 ? signatureLocataireUpload() : signatureProprioUpload()}>Enregistrer la signature</button>
                                    </div>
                                    
                                </div>
                            </div>
                        }
                    </div>

                    <div className='flex'>
                        <Link to={'/mon-espace/contrat/' + get_params.get('reservation_id')} className='btn cl1 bg3 brc1 brc1solid ta-center mb10 d-block mt10'>Retour</Link>
                        <button className='btn cl1 bg3 brc1 brc1solid ta-center mb10 d-block mt10' onClick={() => contratValidation()}>Valider télécharger le contrat</button>
                    </div>
                </div>
            </div>

            <Footer />
            
        </div>
    )

}

export default ContratFinal