import React, { useState, useContext, useEffect } from 'react';

import { Context } from '../class/Context'

import { useNavigate, Link } from "react-router-dom";

import { WEBROOT, APP_NAME, PHOTOS_ROOT } from '../constants/general';

import Func from '../class/Func'

const Header = () => {

    const context = useContext(Context)

    const navigate = useNavigate()

    
    const openMenu = () => {

        document.body.classList.toggle('mn')
    
    }

    return (
        
        <header>

            <div className="ps-r flex c ai-center">
                <div className="flex-end ai-center head">

                    <Link to={'/'} title="Ekriny" className="logo-link"><img src={ "/image/large/logo.png"} alt={APP_NAME} className="logo mr20" /></Link>

                    <span className="menu-res bg1 fz2" onClick={ openMenu }>
                        <svg viewBox="2 2 20 20" fill="none">
                            <path d="M4 18L20 18" stroke="#000" stroke-width="2" stroke-linecap="round"/>
                            <path d="M4 12L20 12" stroke="#000" stroke-width="2" stroke-linecap="round"/>
                            <path d="M4 6L20 6" stroke="#000" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                    </span>

                    <nav>
                        <ul>
                            <li><Link to={'/'}>Accueil</Link></li>
                            <li><Link to={'/louer-ma-voiture'}>Louer ma voiture</Link></li>
                            <li><Link to={'/page/aide'}>Aide</Link></li>
                        </ul>
                    </nav>

                </div>

                <div className="flex-end ai-center">

                    {

                        context.session

                            ?

                            <div className='flex ai-center m_flex m_ml10'>
                                
                                <Link to={"/mon-espace"}  className='ta-center mr20'><span className="icon ri-car-line cl2 fz13">{ context.messages > 0 && <span className='badge'>{ context.messages }</span>}</span> <span className='d-block fz07 cl2'>Reservations</span></Link>
                                <Link to={"/mon-espace/discussions"}  className='ta-center mr20'><span className="icon ri-message-2-line cl2 fz13">{ context.messages > 0 && <span className='badge'>{ context.messages }</span>}</span> <span className='d-block fz07 cl2'>Messages</span></Link>
                                
                                <ul className='menu mr20'>
                                    <li>
                                        <Link to={"/mon-espace"}><img src={ Func.avatar(localStorage.getItem('avatar')) } className="avatar"/></Link>
                                        <ul>
                                            {localStorage.getItem('proMode') == "true" &&
                                                <div>
                                                    <span className='fz08 fw5 d-block mb5'>Partenaires</span>
                                                    <li><Link to={'/mon-espace/publier'} className='cl4'><span className='icon ri-car-line'></span> Publier une annonce</Link></li>
                                                    <li><Link to={'/mon-espace/annonces'} className='cl2'><span className='icon ri-car-line'></span> Mes annonces</Link></li>
                                                </div>  
                                            }
                                            <span className='fz08 fw5 d-block mb5 mt10'>Paramètres</span>
                                            <li><Link to={'/mon-espace/mes-informations'} className='cl2'><span className='icon ri-equalizer'></span> Paramètres du compte</Link></li>
                                            <li><Link to={'/mon-espace/mot-de-passe'} className='cl2'><span className='icon ri-lock'></span> Mots de passe</Link></li>
                                            <li><Link to={'/mon-espace/photo-de-profil'} className='cl2'><span className='icon ri-user'></span> Photo de profil</Link></li>
                                            <li><span className='logout cl2 hover-cl4' onClick={e => Func.logout(context, navigate) }> Déconnexion</span></li>
                                        </ul>
                                    </li>
                                </ul>

                                {/* <Link to={"/mon-espace"}><img src={ Func.avatar(localStorage.getItem('avatar')) } className="avatar"/></Link>
                                <span className="btn2 brc1 brc2solid cl1 d-in-block bg4" onClick={e => Func.logout(context, navigate) }>Déconnexion</span> */}
                            </div>

                            :

                            <>
                            
                                <div className="char flex-end ai-center ml40">
                                    <a href="" target="_blank" className="d-block icon mr15 bg1 cl2 br-r40 fz13 ri-facebook"></a>
                                    <a href="" target="_blank" className="d-block icon bg1 mr20 cl2 br-r40 fz13 ri-instagram"></a>
                                </div>

                                <Link to={"/connexion"} className="btn2 brc1 brc2solid cl1 d-in-block bg3">Se connecter</Link>
                            
                            </>

                    }

                </div>

            </div>

        </header>
    );

}

const Footer = () => {

    return (

        <footer className="bg5 p30 cl2">

            <div className='c grid3'>

                <Link to={'/'} className="logo-link"><img src={ "/image/large/logo.png"} alt={APP_NAME} className="logo mr20" /></Link>

                <div>

                    <h3>Liens utils</h3>

                    <ul className='p0 mt20'>
                        <li><Link to={'/page/conditions-genrales-d-utilisation'}>Conditions générales d'utilisation</Link></li>
                        <li><Link to={'/page/a-propos-de-nous'}>A Propos de nous</Link></li>
                    </ul>

                </div>

                <div>

                    <h3>Suivez nous</h3>

                    <div className='p0 mt20 flex jc-start fz18 m_flex'>
                        <Link to={'/'} className="mr10"><span className='icon ri-facebook'></span></Link>
                        <Link to={'/'}><span className='icon ri-instagram'></span></Link>
                    </div>

                </div>

            </div>

        </footer>

    );

}

const AnnonceCard = (props) => {

    return ( 

        <Link to={ "/annonce/" + props.data.idA } className="article cl2">
                                
            <img src={ PHOTOS_ROOT + 'small/' + props.data.name + '.jpg' } alt={ props.data.titre }/>
            
            <div className="cnt">
                <span className='t mb10'>{ props.data.titre }</span>

                <div className='place'>  
                    <span className='icon ri-map'></span>
                    <span>{ props.data.adresse }</span>
                </div>

                <div className='infos'>  
                    <span className='price'>{ props.data.prix } DA<span className="p"> / j</span></span>
                    
                    {

                        props.data.nbr_note > 0
                        
                        &&

                        <div className="rating">
                            <span className='icon ri-star-fill'></span>
                            <span className='p'>{ props.data.note }</span>
                            <span>({ props.data.nbr_note })</span>
                        </div>

                    }

                </div>
                
            </div>
        </Link>

    )

}

const Loading = () => {

    return (<div className='flex jc-center mt50 mb50 col-10'><div className="loader"></div></div>)

}

export { Header, Footer, AnnonceCard, Loading };