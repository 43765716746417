import React, { useEffect, useState } from 'react'
import { APP_NAME } from '../constants/general'
import { Header , Footer, Loading } from '../components/imports'
import { useParams } from 'react-router-dom'
import Func from '../class/Func'

function Page() {

  const {url} = useParams()

  console.log(url)

  const [article, setArticle] = useState(false)

  const getArticle = async () => {

    const results = await Func.fetch("pages/get", {url: url, lng: localStorage.getItem('i18nextLng')}, false)

    console.log(results)

    setArticle(results.data)

    document.title = results.data.name + " - " + APP_NAME
    
  }

  useEffect((url) => {

    getArticle()

    document.title = "Page - " + APP_NAME

    window.scrollTo(0, 0)

  }, [url])

  return (
    <div>

        <Header/>

        <div className='page cc mt50 p20m mb50'>
    
        {

          article

          ?

              <>

                  <h1 className='ff fz2 cl3 ta-center mb40'>{ article.name }</h1>

                  <div dangerouslySetInnerHTML={ { __html: article.content } }></div>
                  
              </>

          :

              <Loading />

        }
           
        </div>

        <Footer/>

    </div>
  )
}

export default Page